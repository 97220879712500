<template>
  <div class="sof-box-score">
    <GlProgressBarChart
      :progress-bar-height="5"
      :total-funds="100"
    />
  </div>
</template>

<script>
//Components
import GlProgressBarChart from '@/components/charts/gl-progress-bar-chart'

export default {
  components: {
    GlProgressBarChart,
  },
}
</script>

<style>
.sof-box-score {
  width: 100%;
}
.sof-box-score .progress-bar-top-label {
  font-size: 8px;
  margin-bottom: 2px !important;
}
.sof-box-score .progress-bar-total {
  margin-bottom: 4px;
}
.sof-box-score .risk-score-value {
  font-size: 24px;
  line-height: 40px;
}

.sof-box-score .progress-bar-bottom-text {
  font-size: 6px;
}

.sof-box-score
  .a-progress-bar-wrapper
  .progress-outside
  .progress-inner
  .bar-tip:after {
  border-top: 7px solid var(--dark-grey-6-e);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  right: 18%;
}
</style>
